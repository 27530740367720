import React, { useEffect, useState } from "react"
import { SidebarLayout } from "../components/layout"
import { format } from "date-fns"
import { SpecialFrameViewer } from "../components/specialFrameViewer"

export const Entries: React.FC<any> = ({ pageContext }) => {
  const { competition, page, sidebar } = pageContext
  const startDate = format(new Date(competition.start_date), "iii do LLL yyyy")
  const endDate = format(new Date(competition.end_date), "iii do LLL yyyy")
  const [entryId, setEntryId] = useState(null)
  const [frameUrl, setFrameUrl] = useState(page.fileName)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    if (params.has("teamInitials")) {
      // https://www.maple3.co.uk/Masters2020/Tables/MLSummaryTable.htm
      setEntryId(params.get("teamInitials"))

      const url = frameUrl.split("Tables/")[0]
      const competitionSlug = url
        .split("https://www.maple3.co.uk")[1]
        .replaceAll("/", "")

      setFrameUrl(
        url + `Tables/${competitionSlug}Table${params.get("teamInitials")}.htm`
      )
    }
  }, [])

  return (
    <SidebarLayout
      sidebar={{
        competitionSlug: pageContext.competitionSlug,
        navigation: sidebar.navigation,
      }}
      competition={competition}
    >
      <div className="px-5">
        <div className="mb-8">
          <h2 className="font-primary font-black text-3xl">
            {competition.title.text}
          </h2>
          <time>
            {startDate} - {endDate}
          </time>
        </div>
        <div style={{ maxHeight: "900px", overflow: "scroll" }}>
          <SpecialFrameViewer frameUrl={frameUrl} />
        </div>
      </div>
    </SidebarLayout>
  )
}

export default Entries
