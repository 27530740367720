import React from "react"

export const SpecialFrameViewer = ({ frameUrl }) => {
  return (
    <iframe
      src={`${frameUrl}`}
      style={{ width: "100%", height: "900px" }}
    ></iframe>
  )
}
